import { useQuery } from "react-query"
import { useSelector } from "react-redux"
import {
  getAllCustomer,
  getAllProvider,
  getAllCategory,
  getAllCity,
  getAllOrders,
  getNotApprovedProviders,
  getNotApprovedBranches,
  getNotApprovedMenus,
  getNotApprovedAddons,
  getNotActiveReview,
  getAllPages,
  getOrdersBtwDates,
  getOrdersThisyear,
  getOrdersThisMonth,
  getOrdersThisWeek,
  getOrdersThisDay,
  getProviderSearch,
  getAllLocale,
  getLocale,
} from "../../common/Services/dbServices"

import { UserContext } from "Context/userContext"
import { useContext } from "react"

export const useAllCustomer = () => {
  const { user } = useContext(UserContext)
  return useQuery(["allCustomer", user], () => getAllCustomer())
}

export const useAllProvider = () => {
  const { user } = useContext(UserContext)
  return useQuery(["allProvider", user], () => getAllProvider())
}

export const useAllLocale = () => {
  const { user } = useContext(UserContext)
  return useQuery(["allLocale", user], () => getAllLocale())
}

export const useLocale = () => {
  const { user } = useContext(UserContext)
  return useQuery(["Locale", user], () => getLocale())
}

export const useAllCategory = () => {
  const { user } = useContext(UserContext)
  return useQuery(["allCategory", user], () => getAllCategory())
}

export const useAllCity = () => {
  const { user } = useContext(UserContext)
  return useQuery(["allCity", user], () => getAllCity())
}

export const useAllOrders = () => {
  const { user } = useContext(UserContext)
  return useQuery(["allOrders", user], () => getAllOrders())
}

export const useNotApprovedProviders = () => {
  const { user } = useContext(UserContext)
  return useQuery(["notApprovedProviders", user], () =>
    getNotApprovedProviders()
  )
}

export const useNotApprovedBranches = () => {
  const { user } = useContext(UserContext)
  return useQuery(["notApprovedBranches", user], () => getNotApprovedBranches())
}
export const useNotApprovedMenus = () => {
  const { user } = useContext(UserContext)
  return useQuery(["notApprovedMenus", user], () => getNotApprovedMenus())
}
export const useNotApprovedAddons = () => {
  const { user } = useContext(UserContext)
  return useQuery(["notApprovedAddons", user], () => getNotApprovedAddons())
}

export const useNotActiveReview = () => {
  const { user } = useContext(UserContext)
  return useQuery(["notActiveReview", user], () => getNotActiveReview())
}

export const useProvidersSearch = () => {
  const { user } = useContext(UserContext)
  return useQuery(["branches", user], () => getProvidersSearch())
}

export const useAllPages = () => {
  const { user } = useContext(UserContext)
  return useQuery(["allPages", user], () => getAllPages())
}

export const useOrdersBtwDates = ({ startDate, endDate, providerId }) => {
  const { user } = useContext(UserContext)
  return useQuery(["btwdates", user, { startDate, endDate, providerId }], () =>
    getOrdersBtwDates({ startDate, endDate, providerId })
  )
}

export const useOrdersThisYear = ({ startOfYear, endOfYear, providerId }) => {
  const { user } = useContext(UserContext)
  return useQuery(
    ["thisYear", user, { startOfYear, endOfYear, providerId }],
    () => getOrdersThisyear({ startOfYear, endOfYear, providerId })
  )
}

export const useOrdersThisMonth = ({
  startOfMonth,
  endOfMonth,
  providerId,
}) => {
  const { user } = useContext(UserContext)
  return useQuery(
    ["btwdates", user, { startOfMonth, endOfMonth, providerId }],
    () => getOrdersThisMonth({ startOfMonth, endOfMonth, providerId })
  )
}

export const useOrdersThisWeek = ({ startOfWeek, endOfWeek, providerId }) => {
  const { user } = useContext(UserContext)
  return useQuery(
    ["btwdates", user, { startOfWeek, endOfWeek, providerId }],
    () => getOrdersThisWeek({ startOfWeek, endOfWeek, providerId })
  )
}

export const useOrdersTodays = ({ startTodays, endTodays, providerId }) => {
  const { user } = useContext(UserContext)
  return useQuery(
    ["btwdates", user, { startTodays, endTodays, providerId }],
    () => getOrdersThisDay({ startTodays, endTodays, providerId })
  )
}

export const useProviderSearch = () => {
  const { user } = useContext(UserContext)
  return useQuery(["branches", user], () => getProviderSearch())
}
