import React, { useState, useEffect } from "react"
import "../../css/providers.css"
import { Typography, Button } from "@mui/material"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import TextField from "@mui/material/TextField"
import Box from "@mui/material/Box"
// import countries from "world-countries"

import { UpdateOneLocale, AddOneLocale } from "../../common/Services/dbServices"
import LoadingButton from "@mui/lab/LoadingButton"
import SaveIcon from "@mui/icons-material/Save"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"

const AddCountries = ({
  setOpen,
  edit,
  specificLocale = null,
  refetch,
  setShowSuccessAdded,
  setShowError,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [checkedActive, setCheckedActive] = React.useState(true)

  const validationSchema = Yup.object().shape({
    country: Yup.string().required("Country is required"),
    languageCode: Yup.string().required("Language Code is required"),
    currency: Yup.string().required("Currency is required"),
  })

  const handleChangeActive = event => {
    setCheckedActive(event.target.checked)
  }

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  })

  const addNewLocale = async data => {
    console.log("data", data)
    if (edit) {
      setIsLoading(true)
      try {
        const result = await UpdateOneLocale({
          item: specificLocale,
          data,
          isActive: checkedActive,
        })
        if (result) {
          setOpen(false)
          setIsLoading(false)
          refetch()
          setShowSuccessAdded(true)
          setTimeout(() => {
            setShowSuccessAdded(false)
          }, 3000)
        }
      } catch (error) {
        setIsLoading(false)
        setShowError(true)
        setTimeout(() => {
          setShowError(false)
        }, 3000)
      }
    } else {
      setIsLoading(true)
      try {
        const result = await AddOneLocale({
          data,
          isActive: checkedActive,
        })
        if (result) {
          setOpen(false)
          setIsLoading(false)
          refetch()
          setShowSuccessAdded(true)
          setTimeout(() => {
            setShowSuccessAdded(false)
          }, 3000)
        }
      } catch (error) {
        setIsLoading(false)
        setShowError(true)
        setTimeout(() => {
          setShowError(false)
        }, 3000)
      }
    }
  }

  useEffect(() => {
    if (edit) {
      setValue("country", specificLocale?.country)
      setValue("languageCode", specificLocale?.languages[0]?.code)
      setValue("language", specificLocale?.languages[0]?.name)
      setValue("currency", specificLocale?.currency)
      // setValue("isActive", specificLocale?.isActive)
      setCheckedActive(specificLocale?.isActive || false)
    }
  }, [])

  return (
    <div>
      <form>
        <div className="providers-text-field">
          <div className="providers-text-field-row">
            <TextField
              fullWidth
              label="Country Name*"
              id="fullWidth"
              size="small"
              {...register("country", { required: true })}
              error={errors.country ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.country && errors.country?.message
                ? errors.country?.message.toString()
                : null}
            </Typography>
          </div>

          <div className="providers-text-field-row">
            <TextField
              fullWidth
              label="Currency*"
              id="fullWidth"
              size="small"
              {...register("currency", { required: true })}
              error={errors.currency ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.currency && errors.currency?.message
                ? errors.currency?.message.toString()
                : null}
            </Typography>
          </div>
          {/* <div className="providers-text-field-row">
            <TextField
              fullWidth
              label="اسم*"
              id="fullWidth"
              size="small"
              {...register("arabicName", { required: true })}
              error={errors.arabicName ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.arabicName && errors.arabicName?.message
                ? errors.arabicName?.message.toString()
                : null}
            </Typography>
          </div> */}
        </div>
        {/* <div className="providers-text-field">
          
          <div className="providers-text-field-row">
            <TextField
              fullWidth
              label="وصف*"
              id="fullWidth"
              size="small"
              {...register("arabicBio", { required: true })}
              error={errors.arabicBio ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.arabicBio && errors.arabicBio?.message
                ? errors.arabicBio?.message.toString()
                : null}
            </Typography>
          </div>
        </div> */}

        <div className="providers-text-field">
          <div className="providers-text-field-row">
            <TextField
              fullWidth
              label="language*"
              id="fullWidth"
              size="small"
              {...register("language", { required: true })}
              error={errors.language ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.language && errors.language?.message
                ? errors.language?.message.toString()
                : null}
            </Typography>
          </div>
          <div className="providers-text-field-row">
            <TextField
              fullWidth
              label="Language Code*"
              id="fullWidth"
              size="small"
              {...register("languageCode", { required: true })}
              error={errors.languageCode ? true : false}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.languageCode && errors.languageCode?.message
                ? errors.languageCode?.message.toString()
                : null}
            </Typography>
          </div>
        </div>

        <FormControlLabel
          control={
            <Switch
              checked={checkedActive}
              onChange={handleChangeActive}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Active"
          labelPlacement="start"
        />

        {/* <div className="providers-text-field">
          <div className="providers-text-field-row">
            <label className="form-label" htmlFor="type">
              Choose Type Of Your Business*
            </label>
            <Select
              id="type"
              options={options}
              onChange={select => {
                setType(select?.type)
              }}
            />
            <Typography
              variant="inherit"
              color="textSecondary"
              sx={{ textAlign: "center" }}
            >
              {errors && errors.prefix && errors.prefix?.message
                ? errors.prefix?.message.toString()
                : null}
            </Typography>
          </div>
        </div> */}

        <Typography
          variant="inherit"
          color="textSecondary"
          sx={{ textAlign: "center" }}
        >
          {errors &&
            Object.keys(errors).map(errorKey => {
              const errorMessage = errors[errorKey]?.message
              return errorMessage ? (
                <p key={errorKey}>{errorMessage.toString()}</p>
              ) : null
            })}
        </Typography>
        <Box
          textAlign="center"
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "15px",
            marginTop: "30px",
          }}
        >
          {!isLoading ? (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#190c33",
                },
              }}
              onClick={() => setOpen(false)}
            >
              Close
            </Button>
          ) : (
            ""
          )}
          {isLoading ? (
            <LoadingButton
              size="small"
              loading
              loadingPosition="start"
              startIcon={<SaveIcon />}
              variant="outlined"
            >
              Save
            </LoadingButton>
          ) : (
            <Button
              variant="contained"
              sx={{
                "@media (max-width: 600px)": {
                  fontSize: "0.6rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 601px) and (max-width: 960px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
                "@media (min-width: 970px)": {
                  fontSize: "0.8rem",
                  backgroundColor: "#2b87ae",
                },
              }}
              onClick={handleSubmit(addNewLocale)}
            >
              Save
            </Button>
          )}
        </Box>
      </form>
    </div>
  )
}

export default AddCountries
